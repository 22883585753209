import axios from 'axios';

const BASE = "https://www.thebluealliance.com/api/v3";

const TBA = function(eventKey, apiKey) {
    this.eventKey = eventKey;
    this.apiKey = apiKey;
};

TBA.prototype.fetchEvent = async function() {
    const pullEvent = await axios({
        url: `${BASE}/event/${this.eventKey}/simple`,
        headers: { 'X-TBA-Auth-Key': this.apiKey }
    });
    this.eventDetails = pullEvent.data;
}

TBA.prototype.fetchTeams = async function() {
    const pullTeams = await axios({
        url: `${BASE}/event/${this.eventKey}/teams`,
        headers: { 'X-TBA-Auth-Key': this.apiKey }
    });
    this.teams = pullTeams.data;
}

TBA.prototype.fetchRankings = async function() {
    const pullRanking = await axios({
        url: `${BASE}/event/${this.eventKey}/rankings`,
        headers: { 'X-TBA-Auth-Key': this.apiKey }
    });
    this.rankings = pullRanking.data;
}

TBA.prototype.getRankings = function() {
    return this.rankings.rankings;
}

TBA.prototype.getTeams = function() {
    return this.teams;
}

TBA.prototype.getTeamByKey = function(key) {
    return this.teams.find(team => {
        return team.key == key;
    });
}

TBA.prototype.getEvent = function() {
    return this.eventDetails;
}

export default TBA;