<template>
    <!-- <td>
        <span class="is-size-1">{{ rank }}</span>
    </td>
    <td>
        <span class="is-size-1">{{ teamNumber }}</span>
    </td>
    <td>
        <span class="is-size-1">{{ teamName }}</span>
    </td>
    <td>
        <span class="is-size-1">{{ city }}, {{ state }}, {{ country }}</span>
    </td> -->
    <div class="columns data-header">
            <div class="column is-1 is-size-1">
                {{ rank }}
            </div>
            <div class="column is-2 is-size-1">
                {{ teamNumber }}
            </div>
            <div class="column is-size-1">
                {{ teamName }}
            </div>
            <div class="column is-size-1">
                {{ rpScore }}
            </div>
        </div>
</template>

<script>
export default {
    name: 'TableEntry',
    props: {
        teamKey: String,
        rank: Number,
        rpScore: Number,
    },
    data() {
        return {
            teamName: '',
            teamNumber: 0,
            city: '',
            state: '',
            country: '',
        }
    },
    methods: {
        populateData() {
            const team = this.$parent.getTBA().getTeamByKey(this.teamKey);
        
            this.teamName = team.nickname;
            this.teamNumber = team.team_number;
            this.city = team.city;
            this.state = team.state_prov;
            this.country = team.country;
        }
    },
    watch: {
        teamKey: function() {
            this.populateData();
        }
    },
    mounted() {
      this.populateData();  
    }
}
</script>