<template>
  <div v-if="!apiKey" class="notification is-danger"><strong>We didn't find a TBA API Key.</strong> Please contact Brad if this is happening for reals.</div>
  <scoring-table v-else-if="showTable" :event="key" :api="apiKey" />
  <team-list v-else-if="showList" :event="key" :api="apiKey" />
  <div class="is-flex is-flex-direction-column is-justify-content-center is-align-content-center is-size-3 has-text-centered" style="height: 100vh;" v-else>
    <span>TBA Event Key:</span>
    <input placeholder="2022incol" class="is-size-2 has-text-centered my-3 mx-5" v-model="key" />
    <button class="button is-info is-size-3 mx-5" @click="startDisplay" v-if="apiKey">Start Pit Display</button>
    <div class="buttons mt-5 mx-5 is-justify-content-center">
      <button class="button is-dark" @click="startList" v-if="apiKey">Get Team List</button>
    </div>
  </div>
</template>

<script>
import ScoringTable from './components/ScoringTable.vue';
import TeamList from './components/TeamList.vue';
import axios from 'axios';
require('@/assets/main.scss');

export default {
  name: 'FIN BS',
  components: {
    ScoringTable,
    TeamList,
  },
  data() {
    return {
      key: "",
      apiKey: null,
      showTable: false,
      showList: false,
    };
  },
  methods: {
    startDisplay() {
      this.showTable = true;
    },
    startList() {
      this.showList = true;
    }
  },
  async mounted() {
    const key = await axios({
        url: `/key.txt`
    });
    this.apiKey = key.data;
  }
}
</script>

<style>

</style>
