<template>
    <div class="container is-fluid main-header scrollable">
        <h3>{{ eventDetails.name }} | {{ eventDetails.start_date }}-{{ eventDetails.end_date }}</h3>
        <table class="table">
            <thead>
                <th>Number</th>
                <th>Name</th>
                <th>Location</th>
            </thead>
            <tbody>
                <tr v-for="(team, index) in teams" v-bind:key="index">
                    <td>{{ team.team_number }}</td>
                    <td>{{ team.nickname }}</td>
                    <td>{{ team.city }}, {{ team.state_prov }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import TBA from '../lib/tbaData';

    export default {
        name: 'TeamList',
        props: {
            event: String,
            api: String
        },
        data() {
            return {
                tba: {},
                eventDetails: {},
                teams: {}
            }
        },
        async mounted() {
            this.tba = new TBA(this.event, this.api);

            await this.tba.fetchEvent();
            await this.tba.fetchTeams();

            this.eventDetails = this.tba.getEvent();
            this.teams = this.tba.getTeams().sort( (a,b) => { return a.team_number - b.team_number });
        },
        methods: {
            getTBA() {
                return this.tba;
            }
        }
    }
    
</script>