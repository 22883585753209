<template>
    <div class="container is-fluid main-header">
        <div class="columns">
            <div class="column is-one-fifth">
                <img src="../assets/fin.png" />
            </div>
            <div class="column is-flex-title">
                <h1 class="is-size-1">{{ eventDetails.name }}</h1>
            </div>
        </div>
        <div class="columns data-header">
            <div class="column is-1 is-size-1">
                Rank
            </div>
            <div class="column is-2 is-size-1">
                Number
            </div>
            <div class="column is-size-1">
                Team Name
            </div>
            <div class="column is-size-1">
                RP
            </div>
        </div>
    </div>
    <div class="container is-fluid">
        <!-- <table class="table">
            <thead>
                <tr class="is-size-1 table-header">
                    <th>Rank</th>
                    <th>Team Number</th>
                    <th>Team Name</th>
                    <th>Location</th>
                </tr>
            </thead>
        </table> -->
        <!-- <table class="table" id="table">
            <tbody>
                <tr v-for="(team, index) in rankings" v-bind:key="index">
                    <table-entry :teamKey="team.team_key" :rank="team.rank" />
                </tr>
            </tbody>
        </table> -->
        <div id="table">
            <div v-for="(team, index) in rankings" v-bind:key="index">
                <table-entry :teamKey="team.team_key" :rank="team.rank" :rpScore="team.sort_orders[0]" />
            </div>
        </div>
    </div>
    <footer class="footer bottom-header">
        <div class="content has-text-centered">
            <p>Created by <u>Beck Peters</u> &amp; maintained by the FIN Software / AV Team</p>
        </div>
    </footer>
</template>

<script>
import TBA from '../lib/tbaData';
import TableEntry from './TableEntry.vue';

export default {
    name: "ScoringTable",
    components: {
        TableEntry
    },
    props: {
        event: String,
        api: String
    },
    data() {
        return {
            tba: {},
            rankings: [],
            translate: 0,
            eventDetails: {}
        }
    },
    async mounted() {
        this.tba = new TBA(this.event, this.api);

        await this.tba.fetchEvent();
        await this.tba.fetchTeams();
        await this.tba.fetchRankings();

        this.rankings = this.tba.getRankings();

        this.eventDetails = this.tba.getEvent();

        this.translate = 0;
        const windowHeight = window.innerHeight;
        setInterval(() => {
            const tableHeight = document.getElementById("table").getBoundingClientRect().height;
            document.getElementById("table").style.transform = `translateY(${this.translate}px)`;
            this.translate--;
            if(this.translate <= (-1 * (tableHeight - 200))) {
                this.translate = windowHeight - 400;
            }
        }, 33);

        setInterval(async () => {
            await this.tba.fetchRankings();
        }, 180000);
    },
    methods: {
        getTBA() {
            return this.tba;
        }
    }
}
</script>